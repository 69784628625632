import {postData} from '@job-ish/utilities/data';
import {getEnvironmentBaseUrl} from '@job-ish/utilities/url';

import type {Database} from '@job-ish/database/types/supabase';
import type {
	companiesList,
	companiesRetrieve,
	industriesList,
	jobcitiesList,
	jobcountriesList,
	JobRead,
	jobregionsList,
	jobsList,
	jobsRetrieve,
	jobstatesList,
	jobtypesList,
} from '@job-ish/job-data-api/client/job-data-api';
import type {SupabaseClient} from '@supabase/supabase-js';

const createJobBoardJobsRequestUrl = (
	params: Omit<Partial<NonNullable<Parameters<typeof jobsList>[0]>>, 'description'> & {
		description?: string[];
	},
) => {
	const requestUrl = new URL(`${getEnvironmentBaseUrl()}api/job-board/jobs`);
	const searchParams = requestUrl.searchParams;

	for (const [key, value] of Object.entries(params)) {
		if (value) {
			searchParams.set(key, Array.isArray(value) ? JSON.stringify(value) : value.toString());
		}
	}

	return requestUrl;
};

export const fetchJobBoardJobs = async (params: Parameters<typeof createJobBoardJobsRequestUrl>[0]) => {
	const response = await postData<
		Omit<Awaited<ReturnType<typeof jobsList>>, 'data'> & {
			data: Omit<Awaited<ReturnType<typeof jobsList>>['data'], 'next'> & {next?: boolean};
		}
	>({
		url: createJobBoardJobsRequestUrl(params),
		method: 'GET',
	});

	return response;
};

export const fetchJobBoardJob = async (id?: string | null) => {
	const response = await postData<
		Awaited<ReturnType<typeof jobsRetrieve>> & {data: JobRead & {expired: boolean}}
	>({
		url: `${getEnvironmentBaseUrl()}api/job-board/jobs/${id}`,
		method: 'GET',
		next: {revalidate: 604_800},
	});
	return response;
};

export const fetchJobBoardJobSummary = async (id: string) => {
	const response = await postData<string>({
		url: `${getEnvironmentBaseUrl()}api/job-board/jobs/${id}/summary`,
		method: 'GET',
		next: {revalidate: 604_800},
	});
	return response;
};

export const fetchJobBoardJobSalaryInsights = async (id: string) => {
	const response = await postData<{
		min: number;
		max: number;
		average: number;
		currencyCode: string;
	}>({
		url: `${getEnvironmentBaseUrl()}api/job-board/jobs/${id}/salary-insights`,
		method: 'GET',
		next: {revalidate: 604_800},
	});
	return response;
};

export const fetchJobBoardJobTypes = async () => {
	const response = await postData<Awaited<ReturnType<typeof jobtypesList>>>({
		url: `${getEnvironmentBaseUrl()}api/job-board/job-types`,
		method: 'GET',
		next: {revalidate: 604_800},
	});
	return response;
};

export const fetchJobBoardCountries = async () => {
	const response = await postData<Awaited<ReturnType<typeof jobcountriesList>>>({
		url: `${getEnvironmentBaseUrl()}api/job-board/countries`,
		method: 'GET',
		next: {revalidate: 604_800},
	});
	return response;
};

export const fetchJobBoardCities = async (name?: string, countryCode?: string, stateId?: number) => {
	const response = await postData<Awaited<ReturnType<typeof jobcitiesList>>>({
		url: `${getEnvironmentBaseUrl()}api/job-board/cities?name=${name ?? ''}&countryCode=${countryCode ?? ''}&stateId=${
			stateId ?? ''
		}`,
		method: 'GET',
		next: {revalidate: 604_800},
	});
	return response;
};

export const fetchJobBoardStates = async (name?: string, countryCode?: string) => {
	const response = await postData<Awaited<ReturnType<typeof jobstatesList>>>({
		url: `${getEnvironmentBaseUrl()}api/job-board/states?name=${name ?? ''}&countryCode=${countryCode ?? ''}`,
		method: 'GET',
		next: {revalidate: 604_800},
	});
	return response;
};

export const fetchJobBoardCompany = async (id?: number) => {
	const response = await postData<Awaited<ReturnType<typeof companiesRetrieve>>>({
		url: `${getEnvironmentBaseUrl()}api/job-board/companies/${id}`,
		method: 'GET',
		next: {revalidate: 604_800},
	});

	return response;
};

export const fetchJobBoardCompanies = async (name?: string | null) => {
	const response = await postData<Awaited<ReturnType<typeof companiesList>>>({
		url: `${getEnvironmentBaseUrl()}api/job-board/companies?name=${name ?? ''}`,
		method: 'GET',
		next: {revalidate: 604_800},
	});

	return response;
};

export const fetchJobBoardIndustries = async () => {
	const response = await postData<Awaited<ReturnType<typeof industriesList>>>({
		url: `${getEnvironmentBaseUrl()}api/job-board/industries`,
		method: 'GET',
		next: {revalidate: 604_800},
	});

	return response;
};

export const fetchJobBoardRegions = async () => {
	const response = await postData<Awaited<ReturnType<typeof jobregionsList>>>({
		url: `${getEnvironmentBaseUrl()}api/job-board/regions`,
		method: 'GET',
		next: {revalidate: 604_800},
	});

	return response;
};

export const fetchJobBoardCompanyLayoffs = async (supabase: SupabaseClient<Database>, id: number) => {
	const response = await supabase
		.from('layoffs')
		.select('*')
		.eq('ext_company_id', id)
		.order('date', {ascending: false});

	return response;
};
