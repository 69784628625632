import {
	fetchJobBoardCities,
	fetchJobBoardCompanies,
	fetchJobBoardCompany,
	fetchJobBoardCompanyLayoffs,
	fetchJobBoardCountries,
	fetchJobBoardIndustries,
	fetchJobBoardJob,
	fetchJobBoardJobs,
	fetchJobBoardJobSalaryInsights,
	fetchJobBoardJobSummary,
	fetchJobBoardJobTypes,
	fetchJobBoardRegions,
	fetchJobBoardStates,
} from '~/shared/api/job-board';
import {QUERY_KEYS} from '~/shared/constants/keys';
import {useSupabase} from '~/shared/hooks/use-supabase';
import type {JobBoardFilterConfig} from '~/shared/types/job-board-filter';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';

export const useInfiniteJobBoardJobs = (persistedFilter: JobBoardFilterConfig) =>
	useInfiniteQuery({
		queryKey: [QUERY_KEYS.JobBoardJobs, persistedFilter],
		queryFn: async ({pageParam}) =>
			fetchJobBoardJobs({
				...persistedFilter,
				page: pageParam + 1,
			}),
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = lastPage ? allPages.length : 0;
			return nextPage;
		},
		initialPageParam: 0,
		refetchOnWindowFocus: false,
	});

export const useJobBoardJobs = (persistedFilter: JobBoardFilterConfig) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardJobs, persistedFilter],
		queryFn: async () => fetchJobBoardJobs(persistedFilter),
	});

export const useJobBoardJob = (id: string) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardJob, id],
		queryFn: async () => {
			if (Array.isArray(id)) return null;
			return await fetchJobBoardJob(id);
		},
	});

export const useJobBoardJobSummary = (id: string) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardJobSummary, id],
		queryFn: async () => await fetchJobBoardJobSummary(id),
	});

export const useJobBoardJobSalaryInsights = (id: string, {enabled}: {enabled: boolean}) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardJobSalaryInsights, id],
		queryFn: async () => await fetchJobBoardJobSalaryInsights(id),
		enabled,
	});

export const useJobBoardJobsByCompany = (filter: JobBoardFilterConfig) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardJobsByCompany, filter],
		queryFn: async () => await fetchJobBoardJobs(filter),
	});

export const useJobBoardJobTypes = () =>
	useQuery({
		queryKey: QUERY_KEYS.JobBoardJobTypes,
		queryFn: fetchJobBoardJobTypes,
	});

export const useJobBoardCountries = () =>
	useQuery({
		queryKey: QUERY_KEYS.JobBoardCountries,
		queryFn: fetchJobBoardCountries,
	});

export const useJobBoardCities = (name?: string, countryCode?: string, stateId?: number) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardCities, {name, countryCode, stateId}],
		queryFn: async () => await fetchJobBoardCities(name, countryCode, stateId),
	});

export const useJobBoardStates = (name?: string, countryCode?: string) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardStates, {name, countryCode}],
		queryFn: async () => await fetchJobBoardStates(name, countryCode),
	});

export const useJobBoardCompany = (id?: number) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardCompany, id],
		queryFn: async () => await fetchJobBoardCompany(id),
		enabled: !!id,
	});

export const useJobBoardCompanies = (name?: string | null) =>
	useQuery({
		queryKey: [QUERY_KEYS.JobBoardCompanies, name],
		queryFn: async () => await fetchJobBoardCompanies(name),
	});

export const useJobBoardIndustries = () =>
	useQuery({
		queryKey: QUERY_KEYS.JobBoardIndustries,
		queryFn: fetchJobBoardIndustries,
	});

export const useJobBoardRegions = () =>
	useQuery({
		queryKey: QUERY_KEYS.JobBoardRegions,
		queryFn: fetchJobBoardRegions,
	});

export const useJobBoardCompanyLayoffs = (id?: number) => {
	const {supabase} = useSupabase();

	return useQuery({
		queryKey: [QUERY_KEYS.JobBoardJobCompanyLayoffs, id],
		queryFn: async () => {
			if (!id) return;
			const {data} = await fetchJobBoardCompanyLayoffs(supabase, id);
			return data;
		},
	});
};
